@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --accent: rgb(59 130 246);
}

body::-webkit-scrollbar {
  width: 0.5rem;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background: #333;
  /* background: #aaa; */
  border-radius: 2rem;
}

body {
  overflow-x: hidden;
}

* {
  @apply font-inter;
}

.home-header {
  background: url('./assets/jpgs/home-hero-image.jpg') no-repeat center
    center/cover;
  position: relative;
}

.about-header {
  background: url('./assets/jpgs/about-hero-image.jpg') no-repeat center
    center/cover;
  position: relative;
}

.FAQ-page {
  background: url('./assets/jpgs/FAQ-hero-image.jpg') no-repeat center
    center/cover;
  position: relative;
}

/* .contact-page {
  background: url('./assets/jpgs/contact-hero-image.jpg') no-repeat center
    center/cover;
  position: relative;
  background: rgba(0, 0, 0, 0.8);
} */

.register-page {
  background: url('./assets/jpgs/register-hero-image.jpg') no-repeat center
    center/cover;
  position: relative;
}

.login-page {
  background: url('./assets/jpgs/login-hero-image.jpg') no-repeat center
    center/cover;
  position: relative;
}

.header * {
  z-index: 5;
}

.header::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
}

.header.register-page::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
}

.header.login-page::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.85);
  width: 100%;
  height: 100%;
}

@layer components {
  .card {
    @apply my-2 p-4 text-center px-6 flex flex-col justify-center items-center;
  }
  .card svg {
    @apply w-20 h-20 bg-blue-300 text-white p-4 rounded-full;
  }

  .card-heading {
    @apply uppercase font-bold my-2 text-xl;
  }

  .procedures .card-heading {
    @apply text-blue-300;
  }

  .procedures .card-text {
    @apply text-gray-50 text-lg;
  }

  .why-choose-us .card-text {
    @apply text-gray-800 text-lg;
  }

  .why-choose-us .card-heading {
    @apply text-gray-900;
  }

  .plan-price {
    @apply font-bold text-green-600 text-2xl my-4;
  }

  .plan-link {
    @apply py-2 px-6 text-green-500 border-2 border-green-600 font-semibold rounded-lg hover:border-gray-800 hover:bg-gray-800 hover:text-white transition-all duration-500;
  }

  .line {
    @apply w-full h-[2px] my-2 bg-green-500;
  }

  .converter-card {
    @apply p-4 bg-gray-800 m-auto w-[90%] max-w-[600px] text-white drop-shadow-lg;
  }

  .converter-card h1 {
    @apply font-bold mb-4;
  }

  .converter-card form label {
    @apply mb-2 block;
  }

  .testimonies .card {
    @apply bg-white;
  }

  .dashboard-nav .main-nav a {
    @apply flex flex-col items-center justify-center gap-[4px] lg:flex-row lg:py-3 lg:pl-4 lg:pr-16 lg:hover:bg-blue-400 duration-500 transition-all ease-in rounded-xl;
  }

  .padding {
    @apply p-4 sm:px-8 lg:px-16 xl:px-24;
  }
}

.loading-spinner-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.loading-spinner-container * {
  z-index: 100;
}

.loading-spinner {
  width: 6rem;
  height: 6rem;
  margin: 1rem;
  border-width: 1rem;
  animation: rotate 1s linear infinite;
}

.loading-spinner:first-child {
  border-color: #00cc66 transparent #fff transparent;
}

.loading-spinner:last-child {
  border-color: transparent #fff transparent #00cc66;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.dashboard-nav {
  box-shadow: -1px -1px 16px rgba(0, 0, 0, 0.2);
}

.profile-pic-input:before {
  content: 'Add Profile Pic';
  margin: 2px;
}

.tradingview-widget-container iframe:nth-child(2) {
  display: none;
}

.crypto-rates-container {
  height: 374px;
  background-color: #1d2330;
  overflow: hidden;
  box-sizing: border-box;
  border: 1px solid #282e3b;
  border-radius: 4px;
  text-align: right;
  line-height: 14px;
  font-size: 12px;
  font-feature-settings: normal;
  text-size-adjust: 100%;
  box-shadow: inset 0 -20px 0 0 #262b38;
  padding: 0px;
  margin: 0px;
  width: 100%;
}

.crypto-rates {
  height: 354px;
  padding: 0px;
  margin: 0px;
  width: 100%;
}

.crypto-rates-graph {
  color: #626b7f;
  line-height: 14px;
  font-weight: 400;
  font-size: 11px;
  box-sizing: border-box;
  padding: 2px 6px;
  width: 100%;
  font-family: Verdana, Tahoma, Arial, sans-serif;
}

.slide-in-pop-up {
  background: rgba(0, 0, 0, 0.6);
}

.no-text-select {
  user-select: none;
}

input {
  display: block !important;
}

.MuiFormControl-root {
  display: block;
  width: 100%;
  margin: 0.5rem 0 !important;
}

.user-data-p p {
  margin-bottom: 0.5rem;
}

.user-data-p p span:first-child {
  font-weight: 600;
  color: #3b82f6;
  text-transform: uppercase;
}

button {
  transition: 0.5s ease;
}
